<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    en: {},
    pt: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",
    },
    es: {
      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",
    },
  },
  components: { Layout },
  data() {
    return {
      plans: null,
    };
  },
  methods: {
    getPlans() {
      api.get("internet/plans").then((response) => {
        if (response.data.status == "success") {
          this.plans = response.data.list;
        }
      });
    },
  },
  mounted() {
    this.getPlans();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">{{ t("Planos") }}</h4>
        </div>
      </div>
    </div>

    <div v-if="!plans" class="text-center">
      <b-spinner small variant="default"></b-spinner>
    </div>
    <div v-else-if="plans && plans.length == 0" class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body text-center">Nenhum plano disponível no momento.</div>
        </div>
      </div>
    </div>
    <div v-else-if="plans && plans.length > 0" class="row">
      <div class="col-lg-4" v-for="(row, index) in plans" :key="index">
        <div class="card">

          <div class="card-body">
            <div v-if="row.cover" class="card-cover mb-4 col-md-6 p-0">
              <img class="w-100 rounded" :src="row.cover" />
            </div>
            <p class="m-0">Plano</p>
            <h4 class="mb-0">{{ row.name }}</h4>
            <div v-for="(description, index) in row.features" :key="index">
              <i class="bx bx-plus"></i> {{ description }}
            </div>
            <h5 class="mt-2 mb-4">{{ row.value | currency }}/mês</h5>
            <router-link tag="a" :to="'/internet/plans/' + row.id" class="btn btn-default px-4">
              Contratar
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
</style>